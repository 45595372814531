import React, { useState } from "react";
import { IoMdArrowForward } from "react-icons/io";
import LatestFeaturedPlansBgImg from "../../Assests/Home/LatestFeaturesPackackgeBgImg.svg";
import { scrollToSection } from "../../Utils/useCountHelper";
import axios from "axios";
import apiHeader from "../../Utils/apiHeaders";
import Razorpay from "./RozarPay";
import { SweetError, SweetSuccess } from "../../Utils/Toast";

const API_URL = process.env.REACT_APP_API_LINK;
const data = [
  {
    id: 1,
    packageId: "6720ab614705d4017b7bbffc",
    title1: "Facebook Ads Full Course",
    title2: "Google Ads Full Course ",
    amount: 999,
    price: "₹ 999",
  },
  {
    id: 2,
    packageId: "6720ac354705d4017b7bbffe",
    title1: "Stock Market Full Course",
    title2: false,
    amount: 1999,
    price: "₹ 1999",
  },
];

const LatestFeaturedPlans = () => {
  const [isLoaderShow, setIsLoaderShow] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isOrderSuccess, setOrderSuccess] = useState(false);
  const [packageIdSelected, setPackageIdSelected] = useState({});

  const [errors, setErrors] = React.useState({
    referralCode: "",
    userName: "",
    email: "",
    contact: "",
    password: "",
    address: "",
    city: "",
    state: "",
  });

  const [goToPayment, seGoToPayment] = useState(false);

  const [registeredUser, setRegisteredUser] = useState({
    fullName: "",
    userId: "",
  });

  const [formData, setFormData] = useState({
    referralCode: "",
    role: "student",
    userName: "",
    email: "",
    contact: "",
    password: "",
    referralName: "",
    package_id: packageIdSelected,
    sponsor_id: "",
    sponsor_name: "",
    address: "",
    city: "",
    state: "",
  });

  const validateField = (name, value) => {
    let errorMessage = "";

    switch (name) {
      case "referralCode":
        if (!value) errorMessage = "Sponsor Code is required.";
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: errorMessage,
        }));
        break;

      case "userName":
        if (!value) errorMessage = "Username is required.";
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: errorMessage,
        }));
        break;

      case "password":
        if (!value) {
          errorMessage = "Password is required.";
        } else if (value.length < 8) {
          errorMessage = "Atleast 8 characters long.";
        } else if (!/[A-Z]/.test(value)) {
          errorMessage = "Atleast one uppercase letter.";
        } else if (!/[a-z]/.test(value)) {
          errorMessage = "Atleast one lowercase letter.";
        } else if (!/[@$!%*?&]/.test(value)) {
          errorMessage = "Atleast one special character.";
        } else if (!/\d/.test(value)) {
          errorMessage = "At least one number.";
        }
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: errorMessage,
        }));
        break;

      case "email":
        if (!value) errorMessage = "Email is required.";
        else if (!/^\S+@\S+\.\S+$/.test(value))
          errorMessage = "Invalid email format.";
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: errorMessage,
        }));
        break;

      case "contact":
        if (!value) errorMessage = "Contact is required.";
        else if (!/^\d{10}$/.test(value))
          errorMessage = "Contact must be a valid 10-digit number.";
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: errorMessage,
        }));
        break;

      case "address":
        if (!value) errorMessage = "Address is required.";
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: errorMessage,
        }));
        break;

      case "city":
        if (!value) errorMessage = "City is required.";
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: errorMessage,
        }));
        break;

      case "state":
        if (!value) errorMessage = "State is required.";
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: errorMessage,
        }));
        break;

      default:
        break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));

    return !errorMessage; // Return true if no errors
  };

  console.log("Error : ", errors);
  const handleBookNowClick = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);

    setFormData({
      userName: "",
      password: "",
      email: "",
      contact: "",
      referralCode: "",
      referralName: "",
      sponsor_id: "",
      sponsor_name: "",
    });

    setErrors({
      userName: "",
      password: "",
      email: "",
      contact: "",
      referralCode: "",
      address: "",
      city: "",
      state: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    validateField(name, value);
  };

  let userId = "67206ed0edd99782d77addf9";
  let addressId = "67332402cb264d1afba80037";
  let [
    userIdOfRegisteredForPackageUpdate,
    setUserIdOfRegisteredForPackageUpdate,
  ] = useState(null);

  console.log("go to payment", goToPayment);
  const handleRegister = async () => {
    // Example usage
    if (goToPayment) {
      return;
    }

    const isValid = Object.keys(formData).every((field) =>
      validateField(field, formData[field])
    );

    if (!isValid) {
      console.log("Fields not valid", formData);
      return;
    }

    // seGoToPayment(true);

    setModalOpen(false);
    setIsLoaderShow(true);
    try {
      console.log("API URL in handle register", API_URL);
      // Fetch referral information
      // config for fetching user by referral code
      const config1 = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${API_URL}/user/userByIdOwnReferalCode/${+formData?.referralCode}`,
        headers: { ...apiHeader.headers },
      };

      // fields for registering user
      let referralName1 = "";
      let sponsor_id1 = "";
      let sponsor_name1 = "";

      // API call to fetch user by referral code
      const response1 = await axios.request(config1);
      console.log(response1?.data, "response for referral");

      if (!response1?.data) {
        // tostifyError("Incorrect referral code");
        SweetError("Please enter a valid referral code");
        setIsLoaderShow(false);
        setModalOpen(true);
        return;
      }

      referralName1 = response1.data?.user?.userName;
      sponsor_id1 = response1.data?.user?._id;
      sponsor_name1 = response1.data?.user?.userName;

      setFormData((prev) => ({
        ...prev,
        referralName: referralName1,
        sponsor_id: sponsor_id1,
        sponsor_name: sponsor_name1,
      }));

      const data = {
        ...formData,
        role: "student",
        referralName: referralName1,
        sponsor_id: sponsor_id1,
        sponsor_name: sponsor_name1,
        package_id: [],
      };

      try {
        const configForRegister = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${API_URL}/user/register-member`,
          headers: { ...apiHeader.headers },
          data: data,
        };

        const response2 = await axios.request(configForRegister);

        if (response2?.data?.error) {
          //   tostifyError(response2?.data?.error);
          SweetError(response2?.data?.error);
          console.log(response2?.data?.error);
          setIsLoaderShow(false);
          setModalOpen(true);
          return;
        }
        const Register_member_id = response2?.data?.user?.ownReferrableCode;

        setRegisteredUser({
          userId: response2?.data?.user?.ownReferrableCode,
          fullName: response2?.data?.user?.userName,
        });
        // alert("New Member Id: " + Register_member_id);
        // tostifySuccess("Registration successful");

        SweetSuccess("Registration Successful");

        console.log("Member Registered", response2?.data);
        userId = response2?.data?.user?._id;
        setUserIdOfRegisteredForPackageUpdate(response2.data?.user?._id);
        localStorage.setItem("userIdValue", response2.data?.user?._id);

        if (!response2?.data) {
          //  tostifyError("Registration failed");
          SweetError("Registration failed");
          setIsLoaderShow(false);
          setModalOpen(true);
          return;
        }

        // address
        const userAddress = {
          user_id: userId,
          full_name: formData.userName,
          mobile_number: formData.contact,
          country_code: "+91",
          pincode: "560001",
          address: formData.address,
          landmark: "Near Bus Stand",
          city: formData.city,
          state: formData.state,
          country: "India",
          primary_address: true,
          is_active: true,
        };

        try {
          const config3 = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${API_URL}/address/create`,
            headers: { ...apiHeader.headers },
            data: userAddress,
          };

          const response3 = await axios.request(config3);
          console.log("Address created", response3.data);

          localStorage.setItem(
            "addressIdValue",
            response3.data?.userAddress?._id
          );
          setModalOpen(true);
          setIsLoaderShow(false);
          seGoToPayment(true);
        } catch (error) {
          // tostifyError("Adress Update failed");
          SweetError("Adress Update failed");
          console.error(error, "error at address");
          setIsLoaderShow(false);
          return;
        }
      } catch (error) {
        //  tostifyError(error.error);
        SweetError(error.error);
        setIsLoaderShow(false);
        console.error(error, "error at register");
        return;
      }

      // config for register member
    } catch (error) {
      // tostifyError("Incorrect referral code");
      SweetError("Please enter a valid referral code");
      setIsLoaderShow(false);
      setModalOpen(true);
      console.error(error, "error at referal");
      return;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data:", formData); // Log the form data as an object
    handleRegister();
    // add logic for payment
  };

  console.log("selected Package", packageIdSelected);
  return (
    <div
      id="features"
      style={{ backgroundImage: `url(${LatestFeaturedPlansBgImg})` }}
      className="bg-[#120A38] grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-16 p-[32px] lg:p-[60px__110px]"
    >
      {data?.map((item) => (
        <Card
          setPackageIdSelected={setPackageIdSelected}
          key={item.id}
          item={item}
          onBookNow={handleBookNowClick}
        />
      ))}

      {isModalOpen && (
        <div className="fixed py-16 inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-60">
          <form
            className={`bg-white ${
              goToPayment ? "h-fit" : "h-[75vh]"
            }  md:h-fit overflow-y-scroll md:overflow-hidden relative rounded-lg shadow-lg p-6 md:p-8 max-w-lg w-full mx-4 `}
            onSubmit={handleSubmit}
          >
            {goToPayment === false ? (
              <>
                <h2 className="text-2xl font-semibold text-center mb-2 md:mb-6">
                  Book Now
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-1 md:gap-4">
                  <label className="flex flex-col">
                    <span>Sponsor Code</span>
                    <input
                      type="number"
                      name="referralCode"
                      value={formData.referralCode}
                      placeholder="eg: 9876"
                      onChange={handleChange}
                      className={`py-2 px-3 rounded-md border ${
                        errors.referralCode
                          ? "border-red-500"
                          : "border-green-500"
                      } focus:outline-none focus:border-blue-500`}
                    />
                    {errors.referralCode && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.referralCode}
                      </p>
                    )}
                  </label>
                  <label className="flex flex-col">
                    <span>Username</span>
                    <input
                      type="text"
                      name="userName"
                      value={formData.userName}
                      placeholder="eg: Jhon doe"
                      onChange={handleChange}
                      className={`py-2 px-3 rounded-md border ${
                        errors.userName ? "border-red-500" : "border-green-500"
                      } focus:outline-none focus:border-blue-500`}
                    />
                    {errors.userName && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.userName}
                      </p>
                    )}
                  </label>

                  <label className="flex flex-col">
                    <span>Email</span>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      placeholder="eg: john@gmail.com "
                      onChange={handleChange}
                      className={`py-2 px-3 rounded-md border ${
                        errors.email ? "border-red-500" : "border-green-500"
                      } focus:outline-none focus:border-blue-500`}
                    />
                    {errors.email && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.email}
                      </p>
                    )}
                  </label>
                  <label className="flex flex-col">
                    <span>Contact</span>
                    <input
                      type="tel"
                      name="contact"
                      value={formData.contact}
                      onChange={(e) => {
                        // Allow only numeric values and limit the length to 10
                        if (/^\d{0,10}$/.test(e.target.value)) {
                          handleChange(e);
                        }
                      }}
                      placeholder="eg: 8078901234"
                      className={`py-2 px-3 rounded-md border ${
                        errors.contact ? "border-red-500" : "border-green-500"
                      } focus:outline-none focus:border-blue-500`}
                    />
                    {errors.contact && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.contact}
                      </p>
                    )}
                  </label>
                  <label className="flex flex-col">
                    <span>Password</span>
                    <input
                      type="text"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      placeholder="eg: Jhon@123"
                      className={`py-2 px-3 rounded-md border ${
                        errors.password ? "border-red-500" : "border-green-500"
                      } focus:outline-none focus:border-blue-500`}
                    />
                    {errors.password && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.password}
                      </p>
                    )}
                  </label>

                  <label className="flex flex-col">
                    <span>Address</span>
                    <input
                      type="text"
                      name="address"
                      placeholder="eg: Near Bus stand etc."
                      value={formData.address}
                      onChange={handleChange}
                      className={`py-2 px-3 rounded-md border ${
                        errors.address ? "border-red-500" : "border-green-500"
                      } focus:outline-none focus:border-blue-500`}
                    />
                    {errors.address && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.address}
                      </p>
                    )}
                  </label>
                  <label className="flex flex-col">
                    <span>City</span>
                    <input
                      type="text"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                      placeholder="eg: Pune"
                      className={`py-2 px-3 rounded-md border ${
                        errors.city ? "border-red-500" : "border-green-500"
                      } focus:outline-none focus:border-blue-500`}
                    />
                    {errors.city && (
                      <p className="text-red-500 text-sm mt-1">{errors.city}</p>
                    )}
                  </label>
                  <label className="flex flex-col">
                    <span>State</span>
                    <input
                      type="text"
                      name="state"
                      value={formData.state}
                      onChange={handleChange}
                      placeholder="eg: Goa"
                      className={`py-2 px-3 rounded-md border ${
                        errors.state ? "border-red-500" : "border-green-500"
                      } focus:outline-none focus:border-blue-500`}
                    />
                    {errors.state && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.state}
                      </p>
                    )}
                  </label>
                </div>
                <div className="mt-6 flex justify-end gap-4">
                  <button
                    type="button"
                    onClick={() => {
                      seGoToPayment(false);
                      closeModal();
                      setFormData({
                        userName: "",
                        password: "",
                        email: "",
                        contact: "",
                        referralCode: "",
                        referralName: "",
                        sponsor_id: "",
                        sponsor_name: "",
                      });
                    }}
                    className="absolute top-4 right-4 text-gray-500 hover:text-red-500 transition-colors duration-200"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </>
            ) : (
              <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between mt-6 bg-gray-50 p-6 rounded-lg shadow-md">
                <div className="mb-4 lg:mb-0">
                  <p className="text-lg font-semibold text-gray-800">
                    Full Name:{" "}
                    <span className="text-gray-600 font-medium">
                      {registeredUser.fullName}
                    </span>
                  </p>
                </div>
                <div className="mb-4 lg:mb-0">
                  <h2 className="text-lg font-semibold text-gray-800">
                    User ID:{" "}
                    <span className="text-gray-600 font-medium">
                      {registeredUser.userId}
                    </span>
                  </h2>
                </div>
                <button
                  type="button"
                  onClick={() => {
                    seGoToPayment(false);
                    closeModal();
                    setFormData({
                      userName: "",
                      password: "",
                      email: "",
                      contact: "",
                      referralCode: "",
                      referralName: "",
                      sponsor_id: "",
                      sponsor_name: "",
                    });
                  }}
                  className="absolute top-4 right-4 text-gray-500 hover:text-red-500 transition-colors duration-200"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            )}
            {goToPayment == false ? (
              <button
                type="submit"
                className="w-full py-2 bg-[#1791C8] hover:bg-[#3d9cc2] text-white rounded-md"
              >
                Next
              </button>
            ) : (
              <Razorpay
                setIsLoaderShow={setIsLoaderShow}
                setModalOpen={setModalOpen}
                goToPayment={goToPayment}
                seGoToPayment={seGoToPayment}
                closeModal={closeModal}
                setOrderSuccess={setOrderSuccess}
                packageIdImp={formData?.package_id}
                userIdImp={userIdOfRegisteredForPackageUpdate}
                propData={{
                  // amount:
                  //   localStorage.getItem("packageIdValue") ==
                  //   "6720ab614705d4017b7bbffc"
                  //     ? 999
                  //     : 1999,
                  amount: packageIdSelected?.amount,
                  currency: "INR",
                  name: "AGM",
                  description: "Payments toward course package purchase",
                  image:
                    "https://res.cloudinary.com/ddg42g5ug/image/upload/v1730521530/logo_kyxpjw.png",
                  profile_name: formData.userName,
                  email: formData.email,
                  product: "Pro",
                  number: formData.contact,
                  address: formData.address,
                  callback_url: `${API_URL}/razorpay/payment-callback/${
                    localStorage.getItem("userIdValue") || userId
                  }/${
                    localStorage.getItem("packageIdValue") ||
                    packageIdSelected.packageId
                  }/${localStorage.getItem("addressIdValue") || addressId}`,
                  cancel: `${API_URL}/razorpay/payment-cancel`,
                }}
              />
            )}
          </form>
        </div>
      )}

      {isLoaderShow && <PaymentLoaderModal />}
    </div>
  );
};

export default LatestFeaturedPlans;

function Card({ item, onBookNow, setPackageIdSelected }) {
  return (
    <div
      data-aos={`${item.id % 2 === 0 ? "fade-right" : "fade-left"} `}
      className="flex flex-col gap-4 md:gap-10 shadow-md shadow-white bg-white rounded-xl overflow-hidden"
    >
      <div className="bg-[#005678] flex justify-center items-center py-4">
        <h2 className="text-2xl lg:text-4xl font-medium text-white">
          {item.id === 1 ? "PRO PLAN" : "PRO MAX PLAN"}
        </h2>
      </div>
      <div className="bg-white flex justify-center items-center text-[#171717]">
        <ul className="list-disc flex flex-col text-md lg:text-xl gap-4 justify-center">
          <li>{item?.title1}</li>
          {item?.title2 ? <li>{item?.title2}</li> : <p>&nbsp;</p>}
        </ul>
      </div>
      <div className="flex justify-center items-center text-3xl lg:text-5xl mulish-thin font-bold text-[#E22028]">
        {item.price}
      </div>
      <div className="flex justify-center items-center py-4 gap-2 md:gap-4">
        <button
          onClick={() => {
            localStorage.setItem("packageIdValue", item.packageId);
            setPackageIdSelected(item);
            onBookNow();
          }}
          className="py-3 text-xs md:text-md xl:text-lg px-6 flex gap-2 justify-center items-center bg-[#1791C8] text-white rounded-full"
        >
          Book Now <IoMdArrowForward />
        </button>
        <button
          onClick={() => scrollToSection("getintouch")}
          className="py-3 text-xs md:text-md xl:text-lg px-6 flex gap-2 justify-center items-center bg-white border border-[#1791C8] text-[#1791C8] rounded-full"
        >
          Inquire Now <IoMdArrowForward />
        </button>
      </div>
    </div>
  );
}

const PaymentLoaderModal = () => {
  return (
    <div className="fixed inset-0 backdrop-blur-lg  bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className=" rounded-lg  p-8 flex flex-col items-center">
        {/* Spinner */}
        <div className="animate-spin rounded-full h-20 w-20 border-t-4 border-blue-600 border-solid "></div>
        {/* Message */}
        {/* <p className="mt-4 text-lg font-semibold text-gray-700">
          ...
        </p> */}
      </div>
    </div>
  );
};
