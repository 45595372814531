import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import apiHeader from "../../Utils/apiHeaders";
import { SweetError } from "../../Utils/Toast";
import Swal from "sweetalert2";

const API_URL = process.env.REACT_APP_API_LINK;
const RAZORPAY_KEY_ID = process.env.REACT_APP_RAZORPAY_KEY_ID;
const Razorpay = ({
  setIsLoaderShow,
  goToPayment,
  setModalOpen,
  seGoToPayment,
  closeModal,
  setOrderSuccess,
  propData,
  packageIdImp,
  userIdImp,
  image,
  price,
  name,
  packageDetail,
  directAmount,
  levelAmount,
}) => {
  console.log("PRopDAta call", propData.callback_url);
  const [payment, setPayment] = useState(null);
  const [userId, setUserId] = useState(null);
  const [user_data, setUser_data] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState("");
  const [isLoadingAfterPayment, setIsLoadingAfterPayment] = useState(false);
  // const [UserPaymentDetailArray, setUserPaymentDetailArray] = useState("");
  const localSponsor_id = useRef();
  let isOpenRazorpayCheckout;
  let isPackageDetailSave;
  let isAddMultiWalletBalance;

  var UserPaymentDetailArray;
  useEffect(() => {
    if (typeof window !== "undefined") {
      const user_data_Razorpay = JSON.parse(
        localStorage.getItem("user_data_Razorpay")
      );
      setUser_data(user_data_Razorpay);
      setUserId(user_data_Razorpay?._id);
      localSponsor_id.current = user_data_Razorpay?.sponsor_id;
    }

    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initiatePayment = async () => {
    console.log("I am inside initialization");
    //  setIsLoaderShow(true);
    try {
      if (apiHeader) {
        const config = {
          method: "post",
          url: `${API_URL}/razorpay/orders`,
          headers: { ...apiHeader.headers },
          data: propData,
        };

        const response = await axios.request(config);
        console.log("i am resnse", response);
        closeModal(); // closing the modal of bookNow

        return response?.data?.order;
      }
    } catch (error) {
      setLoading(false);
      setIsLoaderShow(false);
      setOrderSuccess(false);
      setModalOpen(false);
      console.error("Error initiating payment:", error);
    }
  };

  const openRazorpayCheckout = async () => {
    setIsLoaderShow(true);
    console.log("I am inside open checkou");
    try {
      const apiKey = RAZORPAY_KEY_ID;
      setLoadingData("Generating Order Id");
      setLoading(true);
      setModalOpen(false);
      const order_id = await initiatePayment();
      if (!order_id) {
        // tostifyError("Sorry Unable to get Order Id");
        SweetError("Sorry Unable to get Order Id");
        setOrderSuccess(false);
        setIsLoaderShow(false);
        setLoading(false);
        return;
      } else {
        // setLoadingData("Loading");
        setLoading(false);
        // setOrderSuccess(true);
      }
      console.log("2nd option");
      const options = {
        key: apiKey,
        amount: propData?.amount,
        currency: propData?.currency,
        name: propData?.name,
        description: `I've just invested in an enriching course, and the value it brings is reflected in the price: ${propData?.amount}. Excited to embark on this learning journey!`,
        image: propData?.image,
        order_id: order_id,
        prefill: {
          name: propData?.profile_name,
          email: propData?.email,
          contact: propData?.number,
        },

        handler: async function (response) {
          console.log(" 3rd i am in handle", response);
          setIsLoadingAfterPayment(true);
          seGoToPayment(false);
          setLoading(true);
          try {
            const config = {
              method: "post",
              url: propData?.callback_url,
              headers: { ...apiHeader.headers },
              data: response,
            };
            setIsLoadingAfterPayment(true);
            const paymentResponse = await axios.request(config);

            if (paymentResponse?.data?.payment_status === true) {
              // isOpenRazorpayCheckout = true;

              setOrderSuccess(true);
              setIsLoaderShow(false);
              // its time to update the user package detials

              console.log(
                "userid and packageid updated",
                userIdImp,
                packageIdImp,
                localStorage.getItem("userIdValue")
              );

              const configForPackageUpdate = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${API_URL}/user/update/${localStorage.getItem(
                  "userIdValue"
                )}`,
                headers: { ...apiHeader.headers },
                data: {
                  package_id:
                    packageIdImp || localStorage.getItem("packageIdValue"), // Send the packageId in the request body
                },
              };

              try {
                const response = await axios.request(configForPackageUpdate);
                setIsLoadingAfterPayment(true);
                setLoading(true);
                seGoToPayment(false);
                if (response.status === 200) {
                  console.log("Package ID updated successfully!");
                  setLoading(false);
                  seGoToPayment(false);
                  // window.location.href = "https://lms-agm.vercel.app";
                } else {
                  console.log("Failed to update Package ID.");
                }
              } catch (error) {
                seGoToPayment(false);
                setIsLoaderShow(false);
                console.error("Error updating package ID:", error);
                // alert("An error occurred while updating the package ID.");
              }

              setIsLoadingAfterPayment(false);
              // tostifySuccess(
              //   "Thank you for purchasing the course. Your payment has been successfully processed. Please check the mail for username and password🚀"
              // );
              // SweetSuccess("t")

              Swal.fire({
                title: "Good job!",
                text: "Thank you for purchasing the course.Please check the mail for username and password🚀",
                icon: "success",
                footer:
                  '<a className="underline text-blue-500" href="http://business.agmonlinemarketing.com">Go To AGM</a>',
              });

              // setLoadingData("Saving Package Detail");
              // setLoading(true);
              // packageDetailSave(userId);
            }
          } catch (error) {
            setIsLoaderShow(false);
            console.error("Error handling Razorpay response:", error);
          }
        },
        modal: {
          ondismiss: function () {
            Swal.fire({
              title: "Are you sure?",
              text: "You want to close the form?",
              icon: "warning",
              confirmButtonText: "Yes, close it",
              // cancelButtonText: "No, continue",
            }).then((result) => {
              if (result.isConfirmed) {
                console.log("Checkout form closed by the user");
                setIsLoaderShow(false);
              } else {
                console.log("Complete the Payment");
              }
            });
          },
        },
      };
      try {
        console.log("1st i am near window open rozarpay");
        const rzp = new window.Razorpay(options);
        rzp.open();
        // setIsLoaderShow(false);
      } catch (error) {
        setIsLoaderShow(false);
        console.error("Failed to open Razorpay payment window:", error);
        // Show an error message to the user
        alert("Unable to initiate payment. Please try again later.");
      }
    } catch (error) {
      setIsLoaderShow(false);
      console.error("Error opening Razorpay checkout:", error);
    }
  };

  console.log("propData-------------go to payment--->>", goToPayment);

  return (
    <>
      {/* {loading && <PaymentLoaderModal />}
      {isLoadingAfterPayment && <PaymentLoaderModal />} */}
      <div>
        {payment && (
          <div>
            <h2>Payment Successful!</h2>
            <p>Payment ID: {payment?.razorpay_payment_id}</p>
          </div>
        )}
      </div>
      <button
        onClick={openRazorpayCheckout}
        className="mt-8 border bg-black rounded-full hover:bg-blue-500 flex flex-row justify-center items-center space-x-2 py-4 w-full"
      >
        <div className="w-10 h-4">{image}</div>
        <div>
          <p className="text-base text-white bold leading-4">Pay</p>
        </div>
      </button>
    </>
  );
};

export default Razorpay;

const PaymentLoaderModal = () => {
  return (
    <div className="fixed inset-0 backdrop-blur-lg  bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className=" rounded-lg  p-8 flex flex-col items-center">
        {/* Spinner */}
        <div className="animate-spin rounded-full h-20 w-20 border-t-4 border-blue-500 border-solid "></div>
        {/* Message */}
        {/* <p className="mt-4 text-lg font-semibold text-gray-700">
          ...
        </p> */}
      </div>
    </div>
  );
};
