// import React from 'react'

// const Navbar = () => {
//   return (
//     <nav className="bg-gray-800 text-white">
//       <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
//         <div className="flex justify-between items-center py-3 md:justify-start md:space-x-10">
//           <div className="flex justify-start lg:w-0 lg:flex-1">
//             <a href="#">
//               <span className="sr-only">Your Company</span>
//             </a>
//           </div>
//           <div className="hidden md:flex space-x-10">
//             <a href="#" className="text-base font-medium text-gray-300 hover:text-white">Home</a>
//             <a href="#" className="text-base font-medium text-gray-300 hover:text-white">About</a>
//             <a href="#" className="text-base font-medium text-gray-300 hover:text-white">Services</a>
//             <a href="#" className="text-base font-medium text-gray-300 hover:text-white">Contact</a>
//           </div>
//         </div>
//       </div>
//     </nav>
//   )
// }

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AGMLOgoImg from "../../Assests/Home/AGMLogo.svg";
import { GiHamburgerMenu } from "react-icons/gi";
import { useWindowSize } from "../../Utils/useWindowSize";
import { AiOutlineClose } from "react-icons/ai";

const Navbar = () => {
  const { width } = useWindowSize();
  const [isPhoneScreen, setIsPhoneScreen] = useState(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const navigate = useNavigate();

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (width < 1024) {
      setIsPhoneScreen(true);
    } else {
      setIsPhoneScreen(false);
    }
  }, [width]);

  return (
    <nav
      id="navbar"
      className="bg-[#0C0C25] text-white p-[16px_32px] lg:p-[8px_130px_8px_110px]  "
    >
      <div className=" mx-auto flex justify-between items-center">
        <div className="flex flex-col items-center   ">
          <img
            onClick={() => {
              navigate("/");
              setIsSideBarOpen(false);
              scrollToSection("navbar");
            }}
            src={AGMLOgoImg}
            alt="AGMLogo"
            className="w-auto h-10 md:h-fit md:w-auto"
          />
        </div>

        <div className="hidden lg:flex space-x-8  text-[12px] mulish-thin">
          <span
            onClick={() => {
              navigate("/");
              scrollToSection("home");
            }}
            className="text-gray-300 cursor-pointer hover:text-white"
          >
            HOME
          </span>
          <span
            onClick={() => {
              navigate("/");
              scrollToSection("market");
            }}
            className="text-gray-300 cursor-pointer hover:text-white"
          >
            COURSES
          </span>
          <span
            onClick={() => {
              navigate("/");
              scrollToSection("features");
            }}
            className="text-gray-300 cursor-pointer hover:text-white"
          >
            FEATURES
          </span>
          <span
            onClick={() => {
              navigate("/");
              scrollToSection("about");
            }}
            className="text-gray-300 cursor-pointer hover:text-white"
          >
            ABOUT
          </span>
          <span
            onClick={() => {
              navigate("/");
              scrollToSection("review");
            }}
            to="/review"
            className="text-gray-300 cursor-pointer hover:text-white"
          >
            REVIEW
          </span>
        </div>

        {isPhoneScreen ? (
          <div className="text-2xl font-bold ">
            {!isSideBarOpen ? (
              <GiHamburgerMenu
                onClick={() => {
                  setIsSideBarOpen(!isSideBarOpen);
                }}
              />
            ) : (
              <AiOutlineClose
                onClick={() => {
                  setIsSideBarOpen(!isSideBarOpen);
                }}
              />
            )}
          </div>
        ) : (
          <div className=" hidden  lg:flex gap-4  text-white py-2 px-6 rounded-full">
            <a
              rel="noreferrer"
              href="http://business.agmonlinemarketing.com"
              target="_blank"
              className="flex py-3 text-sm px-8  justify-center  bg-[#1791C8]  hover:bg-blue-600 text-white rounded-full"
            >
              Login
            </a>
            <button
              onClick={() => {
                navigate("/");
                scrollToSection("contact");
              }}
              className="flex py-3 text-sm px-8  justify-center  bg-white  hover:bg-slate-300 text-black font-semibold rounded-full"
            >
              Contact Us
            </button>
          </div>
        )}
      </div>

      <div
        className={`${
          isSideBarOpen ? "w-full h-[95vh]" : "w-0 h-0 hidden"
        } flex flex-col gap-8 items-center py-40 text-center transition-all duration-300 ease-in-out `}
      >
        <span
          onClick={() => {
            navigate("/");
            setIsSideBarOpen(false);
            scrollToSection("home");
          }}
          className="text-gray-400 cursor-pointer hover:text-white"
        >
          HOME
        </span>
        <span
          onClick={() => {
            navigate("/");
            setIsSideBarOpen(false);
            scrollToSection("market");
          }}
          className="text-gray-400 cursor-pointer hover:text-white"
        >
          COURSES
        </span>
        <span
          onClick={() => {
            navigate("/");
            setIsSideBarOpen(false);
            scrollToSection("features");
          }}
          className="text-gray-400 cursor-pointer hover:text-white"
        >
          FEATURES
        </span>
        <span
          onClick={() => {
            navigate("/");
            setIsSideBarOpen(false);
            scrollToSection("about");
          }}
          className="text-gray-400 cursor-pointer hover:text-white"
        >
          ABOUT
        </span>
        <span
          onClick={() => {
            navigate("/");
            setIsSideBarOpen(false);
            scrollToSection("review");
          }}
          className="text-gray-400 cursor-pointer hover:text-white"
        >
          REVIEW
        </span>

        <a
          rel="noreferrer"
          href="http://business.agmonlinemarketing.com"
          target="_blank"
          className="flex py-3 mt-10 text-center w-fit text-sm px-12  justify-center  bg-[#1791C8]  hover:bg-blue-600 text-white rounded-full"
        >
          Login
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
